import React, { useContext, useEffect, useState } from 'react'
import { Menu as UikitMenu, ConnectorId } from '@zoomprotocol/uikit'
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import { allLanguages } from 'constants/localisation/languageCodes'
import { LanguageContext } from 'contexts/Localisation/languageContext'
import useTheme from 'hooks/useTheme'
import useGetPriceData from 'hooks/useGetPriceData'
import { injected, bsc, walletconnect } from 'connectors'
import axios from 'axios'
import config from './config'
import { setupNetwork } from '../../utils/wallet'
import useI18n from '../../hooks/useI18n'

const Menu: React.FC = (props) => {
  const { account, activate, deactivate } = useWeb3React()
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)
  const { isDark, toggleTheme } = useTheme()
  const TranslateString = useI18n()
  const links = config.map((c) => {
    // eslint-disable-next-line no-param-reassign
    if (c.translationId != null) {
      c.label = TranslateString(c.translationId, c.label)
    }
    if (c.items) {
      c.items.forEach((i) => {
        // eslint-disable-next-line no-param-reassign
        if (i.translationId != null) {
          i.label = TranslateString(i.translationId, i.label)
        }
      })
    }
    return c
  })
  const [zoomPriceUsd, setZoomPriceUsd] = useState(0)

  useEffect(() => {
    axios
      .get('https://api.coingecko.com/api/v3/simple/price?ids=zoomswap&vs_currencies=usd')
      .then((res) => setZoomPriceUsd(res.data.zoomswap.usd))
  }, [])
  return (
    <UikitMenu
      links={links}
      priceLink="https://www.coingecko.com/en/coins/zoomswap"
      account={account as string}
      login={(connectorId: ConnectorId) => {
        if (connectorId === 'walletconnect') {
          return activate(walletconnect)
        }

        if (connectorId === 'bsc') {
          return activate(bsc)
        }

        return activate(injected, async (error) => {
          if (error instanceof UnsupportedChainIdError) {
            const hasSetup = await setupNetwork()
            if (hasSetup) {
              activate(injected)
            }
          }
          console.log(error)
        })
      }}
      logout={deactivate}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={selectedLanguage && selectedLanguage.language}
      langs={allLanguages}
      setLang={setSelectedLanguage}
      cakePriceUsd={zoomPriceUsd}
      {...props}
    />
  )
}

export default Menu
