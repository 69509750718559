import styled from 'styled-components'
import { Card, Flex, CloseIcon } from '@zoomprotocol/uikit'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useI18n from '../../hooks/useI18n'

// background: #fffbe6;
const NoticeWrapper = styled(Card)`
  background: #fffbe6;
  color: rgba(0, 0, 0, 0.85);
  border-radius: 10px;
  margin-bottom: 40px;
  padding: 20px;
  padding-right: 80px;
  position: relative;
`
const NoticeClose = styled(Flex)`
  position: absolute;
  width: 24px;
  height: 24px;
  right: 2px;
  top: 2px;
  background-color: '#fffbe6';
  cursor: pointer;
`

function Notice() {
  const TranslateString = useI18n()
  const notice_hidden = localStorage.getItem('notice_hidden')
  const h = !!notice_hidden
  const [hidden, setHidden] = useState(h)
  return !hidden ? (
    <NoticeWrapper>
      {TranslateString(
        10015,
        'Warning: This is beta version of ZoomSwap. It is still experimental.ZoomSwap only works with Web3 wallet (Metamask, ioPay, etc.) on IoTeX 1.2+.'
      )}
      <NoticeClose
        onClick={() => {
          setHidden(true)
          localStorage.setItem('notice_hidden', 'true')
        }}
      >
        <CloseIcon />
      </NoticeClose>
    </NoticeWrapper>
  ) : (
    <div />
  )
}

export default Notice
